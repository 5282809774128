@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: #fafafa;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}

#a11y-status-message {
  /*Sfarfallio finestra di selezione soggetti nel pianificarore */
  margin: 0px !important;
}

.red-border {
  color: red !important;
  font-size: large !important;
}

.leaflet-top {
  top: 110px;
}

.MuiCard-root form {
  padding-bottom: 80px;
}

.MuiTablePagination-spacer {
  flex: none !important;
}

.MuiTablePagination-toolbar {
  position: fixed !important;
  bottom: 13px;
  height: 40px;
  min-height: 40px !important;
  background-color: white;
  width: 100%;
  z-index: 999;
}

.MuiTableCell-sizeSmall {
  padding: 0px 0px 0px 5px !important;
}

.list-page {
  padding-bottom: 55px;
  overflow: initial;
}

.MuiTypography-body2 {
  font-size: 0.8rem;
}

/*
.filter-field>div>div {
  max-width: 150px;
}

.filter-field>div>div>div {
  max-width: none;
}


.filter-field-small {
  max-width: 150px !important;
}

.filter-field-medium>div>div {
  max-width: 200px !important;
}

.filter-field-large>div {
  max-width: 300px !important;
}

.filter-field-no-limit>div {
  max-width: none !important;
}

.red-bg {
  background-color: red;
}
*/

.filter-field-small {
  max-width: 150px !important;
}

.MuiFilledInput-inputMarginDense {
  padding: 0px 0px 0px 0px;
}

.MuiFilledInput-input {
  padding: 17px 12px 10px 0px;
}

.MuiButton-root {
  min-width: 10px !important;
}

#secondary-app-bar {
  max-height: 200px;
  overflow: auto;
}

.leaflet-tooltip-no-bg {
  background-color: transparent;
  border: transparent;
}


.ColorInput-popup {
    position: absolute;
    z-index: 2;
}

.ColorInput-cover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.sequence-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circles-sequence {
  display: flex;
  justify-content: center;
  align-items: center;
  width: initial;
  grid-gap: 1px;
  gap: 1px;
}

.circles-sequence span {
  background: black;
  color: white;
  border-radius: 100%;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.info-legend {
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  bottom: 55px;
  max-width: 200px;
  word-break: break-word;
}

.legend-container {
  display: flex;
  flex-direction: column;
}
.legend-container > div {
  display: flex;
  align-items: baseline;
}

.hide-control {
  display: none;
}

.LoadDeliveriesList_load_delivery_container__1kVZw {
    flex: 1 1 auto;
    position: relative;
    margin-top: 0;
    overflow-x: visible;
    transition: margin-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-y: hidden;
}
